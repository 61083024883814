import React from "react"
import Layout from "../../components/layout";
import Promo from "../../components/promo";
import Feature from "../../components/feature";
import Title from "../../components/title";
import { Helmet } from 'react-helmet';
import { Link } from "gatsby";

import PDF from "../../../static/icons/pdf.inline.svg";
import Overdue from "../../../static/icons/overdue.inline.svg";
import Email from "../../../static/icons/email.inline.svg";
import Currency from "../../../static/icons/currency.inline.svg";
import Arrow from "../../../static/icons/arrow.inline.svg";

const Product = ({ location }) => (
    
    <Layout 
      path={location.pathname}>

      <Helmet
        bodyAttributes={{
          class: 'bg-root text-white'
        }} />
      
      <Title
        sub="Invoice"
        colour="green-500"
        title="Raise invoices in any currency."
        width="max-w-md">

        <p className="max-w-xl leading-relaxed mx-auto my-8 text-light text-center text-lg md:text-xl">
          Getting paid as a freelancer can be frustrating, especially if you forget to invoice your clients in a timely manner.
          Slate makes it easy to keep track of the work you have invoiced, and the work you still need to invoice.
        </p>

        <Link to="https://app.heyslate.com/register" className="max-w-xl mx-auto flex items-center justify-center space-x-2 text-accent md:text-lg mt-6">
          <span clasName="text-accent">Get started for free</span>
          <Arrow/>
        </Link>

      </Title>

      <div className="lg:container max-w-6xl mx-auto md:text-lg">

        <div className="mac">

          <div className="relative pb-16/9 rounded-lg overflow-hidden">
            <video className="outline-none absolute w-full h-full object-cover" autoPlay muted loop playsinline>
              <source src="/videos/feature_invoice.mp4" type="video/mp4" />
            </video>
          </div>

        </div>

        <div className="lg:flex lg:space-x-8">

          <div className="lg:w-1/2 space-y-4 sm:space-y-8">

            <Feature 
              icon={<Overdue />}
              title="A simple reminder system."
              text="Forgetting what work you've invoiced can be costly, especially if you're using separate apps for your schedule
              and your invoicing. With Slate you don't need to guess, we keep track of the work you add to your schedule and make sure you
              never forget to invoice it."
              tight={false}
              colour="green-500" />

            <Feature 
              icon={<PDF />}
              title="Beautiful PDF for every invoice."
              text="We create a beautiful PDF for every invoice you raise which you can send to your clients directly from Slate,
              or download to email it to them yourself."
              tight={false}
              colour="green-500" />

          </div>

          <div className="lg:w-1/2 space-y-4 sm:space-y-8">

            <Feature 
              icon={<Currency />}
              title="Multi currency support built-in."
              text="Slate makes it simple to create invoices in different currencies, so if you've got a client based in the UK and one in the US
              we'll automatically generate invoices for their local currency."
              tight={false}
              colour="green-500" />

            <Feature 
              icon={<Email />}
              title="Send to your clients inbox."
              text="Whenever you generate an invoice, Slate gives you the option of sending it directly to your clients inbox or downloading
              a PDF that you can send yourself."
              tight={false}
              colour="green-500" />

          </div>
          
        </div>

      </div>

      <Promo theme="dark" />

    </Layout>
)

export default Product;